.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: url(img/status-bg.png);
    background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.form-wrap h2 {
  font-size: 28px;
  margin-top: 0;
}
.form-wrap h3 {
  font-size: 14px;
  margin: 0 0 30px 0;
}
section.unstake-area {
  color: rgb(255 255 255 / 87%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  overflow: hidden;
  border-radius: 27px;
  background: linear-gradient(90deg, black, black, black);
  max-width: 100vh;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: linear-gradient(90deg, rgba(249, 195, 6, 0), rgba(249, 195, 6, 0.3), rgba(249, 195, 6, 0)) 1 / 1 / 0 stretch;
  padding: 40px;
  text-align: center;
}
.footer-newsletter form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.footer-newsletter form input {
  display: block;
  background: transparent;
  border: 1px solid #414141;
  padding: 15px;
  min-height: 25px;
  font-size: 14px;
  color: #fff;
  width: 300px;
}
.footer-newsletter form button {
  padding: 15px 25px;
  width: 40%;
  text-align: center;
  font-size: 20px;
  background: linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #f9c306 0, #e03e1a 100%) border-box !important;
  border: 1px solid transparent !important;
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
}
.success,
.danger {
  display: none;
}

